.amount {
  font-size: 46px;
  color: white;
  font-weight: 500;
  font-family: 'Poppins';
}
.count-des{
  font-size: 17px;
  color: white;
  font-weight:300;
  opacity:.9;
  font-family: 'Poppins';
}

/* ---------------------- CARD SECTION ----------------------
____________________________________________________________*/

.card-title{
  font-size: 16px;
  font-weight: 600;
  font-family: 'Poppins';
}
.card-content{
  font-size: 14px;
  font-weight: 400;
  font-family: 'Poppins';
  opacity: .9;
}

/* ---------------------- SEARCH BOX ----------------------
____________________________________________________________*/

  .search_field {
    outline: none;
    color: gray;
    font-weight: 500;
    font-size: 18px;
  }

  .card_shadow{
    box-shadow: 1px 18px 20px rgb(193, 194, 220);
  }

@media (max-width: 600px) {
  .amount {
    font-size: 39px;
  }
  .count-des{
    font-size: 16px;
  }
  .card-title{
    font-size: 24px;
  }
  .card-content{
    font-size: 16px;
  }
  .card_shadow{
    box-shadow: 1px 8px 20px rgb(193, 194, 220);
  }
}